import React from "react";
import { getDatabase, ref, remove } from "firebase/database";
import Cookies from "js-cookie";

const NotesItem = ({ id, tema, time, text }) => {
  const handleDelete = () => {
    // Reference to the database node
    const dbRef = ref(
      getDatabase(),
      `/administracija/korisnici/${Cookies.get("userData")}/biljeske/${id}`
    );
    // Remove the node from Firebase
    remove(dbRef)
      .then(() => {
        console.log("Node deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting node:", error);
      });
  };
  return (
    <div className="notice-board-card">
      <button className="notes-delete" alert={"ajoj"} onClick={handleDelete}>
        <img
          className="program-img-notes"
          src={process.env.PUBLIC_URL + "/Smece.png"}
          alt="Delete a note"
        />
      </button>
      <h3>{tema}</h3>
      <p>{time}</p>
      <p className="p2">{text}</p>
    </div>
  );
};
export default NotesItem;
