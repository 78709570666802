import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import { openDB } from "idb";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

async function storeData(data) {
  const db = await openDB("welcomeDB", 1, {
    upgrade(db) {
      db.createObjectStore("welcome");
    },
  });

  const tx = db.transaction("welcome", "readwrite");
  const store = tx.objectStore("welcome");
  //console.log("TESTING SPREMANJE welcoma: ", data);
  await store.put(data, "welcome");
  await tx.done;
}

async function getData() {
  const db = await openDB("welcomeDB", 1);
  return db.get("welcome", "welcome");
}

const Data = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (navigator.onLine) {
        const dbRef = ref(db, "/event/dobrodosli");
        const onDataChange = (snapshot) => {
          const items = snapshot.val();
          if (items) {
            setData(items);
            storeData(items);
          }
        };
        onValue(dbRef, onDataChange);
        return () => {
          onValue(dbRef, null);
        };
      } else {
        const offlineData = await getData();
        console.log("offline data: ", offlineData);
        if (offlineData) {
          setData(offlineData);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Welcome"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div className="container">
        <p dangerouslySetInnerHTML={{ __html: data.text }} class="fs-6 p" />
        <b>
          <p dangerouslySetInnerHTML={{ __html: data.potpis }} class="fs-6 p" />
        </b>
      </div>
    </div>
  );
};
export default Data;
