import React, { Component } from "react";
import Modal from "react-modal";
import Cookies from "js-cookie";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, update, child } from "firebase/database";
import firebaseConfig from "../../firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const currentDate = new Date();

export default class ProgramRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      textAreaValue: "",
    };
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleTextAreaChange = (e) => {
    this.setState({ textAreaValue: e.target.value });
  };

  handleSave = async () => {
    const formattedDateTime = currentDate.toLocaleDateString("hr-HR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // Use 24-hour format
    });

    const { tema } = this.props;
    const { textAreaValue } = this.state;
    const { predavaci } = this.props;

    if (tema && predavaci && textAreaValue) {
      const dbRef = ref(
        db,
        `administracija/korisnici/${Cookies.get("userData")}/biljeske`
      );

      try {
        // Push data to Firebase Realtime Database
        const newNoteRef = push(dbRef, {
          predavac: predavaci,
          tema: tema,
          text: textAreaValue,
          time: formattedDateTime,
          id: null,
        });

        // Get the generated key
        const generatedKey = newNoteRef.key;
        update(child(dbRef, generatedKey), { id: generatedKey });
        alert("Your note hase been saved!");
        this.closeModal();
      } catch (error) {
        console.error("Error pushing data:", error);
      }
    } else {
      console.error("Required properties are undefined.");
    }
  };

  isEmailCookieStored = () => {
    return !!Cookies.get("userData");
  };

  render() {
    let bgColor;
    let txColor;

    if (this.props.razina === "1") {
      bgColor = "#053679";
      txColor = "white";
    } else if (this.props.razina === "2") {
      bgColor = "grey";
      txColor = "white";
    } else if (this.props.razina === "3") {
      bgColor = "#5fbaef";
      txColor = "black";
    }

    return (
      <div>
        <div className="container">
          <div
            class="row flex"
            style={{ backgroundColor: bgColor, color: txColor }}
          >
            <div class="col-3 fs-7 ">
              <div class="">{this.props.vrijeme}</div>
              <br />
              <img className="program-img-logo" src={this.props.opis} alt="" />
            </div>
            <div class="col-7">
              {this.props.tema}
              <br />
              <strong>{this.props.predavaci}</strong>
            </div>
            <div className="col-0">
              {this.props.razina !== "1" && this.props.razina !== "2" && (
                <>
                  {this.isEmailCookieStored() && (
                    <button
                      className="btn btn-link text-decoration-none "
                      onClick={this.openModal}
                    >
                      <i class="bi bi-pencil-fill bijela-boja" />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <table className="tablica-program">
          <tbody
            className="card-program-row"
            style={{ backgroundColor: bgColor, color: txColor }}
          >
            <tr>
              <td className="tablica-program-stuapc1">
                <div>
                  {this.props.vrijeme}
                  <br />
                  <img
                    className="program-img-logo"
                    src={this.props.opis}
                    alt=""
                  />
                </div>
              </td>
              <td className="tablica-program-stuapc2">
                <p>
                  {this.props.tema}
                  <br />
                  <b>{this.props.predavaci}</b>
                </p>
              </td>

              <td className="tablica-program-stuapc3">
                {this.props.razina !== "1" && this.props.razina !== "2" && (
                  <>
                    {this.isEmailCookieStored() && (
                      <button
                        style={{
                          background: "rgba(0, 0, 0, 0.0)",
                          border: "0",
                        }}
                        onClick={this.openModal}
                      >
                        <img
                          className="program-img-notes"
                          src={process.env.PUBLIC_URL + "/Notes.png"}
                          alt="Write a note"
                        />
                      </button>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table> */}
        {/* Modal */}
        <Modal
          className="modal-content"
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Text Entry Modal"
        >
          <h2>{this.props.tema}</h2>
          <textarea
            value={this.state.textAreaValue}
            onChange={this.handleTextAreaChange}
          />
          <button className="modal-content-btn" onClick={this.handleSave}>
            Save
          </button>
          <button className="modal-content-btn" onClick={this.closeModal}>
            Cancel
          </button>
        </Modal>
      </div>
    );
  }
}
