import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const Sponsors = () => {
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showLoading, setShowLoading] = useState(true);

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, "/event/sponzori");
      const onDataChange = (snapshot) => {
        const items = snapshot.val();
        if (items) {
          setData(items);
        }
      };
      onValue(dbRef, onDataChange);
      return () => {
        onValue(dbRef, null);
      };
    };

    setTimeout(() => {
      setImageUrl(data.web);
      setShowLoading(false);
      setShowImage(true);
    }, 2000);

    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Sponsors"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div className="container">
        {showLoading && (
          <div className="">
            {/* Loading indicator */}
            <p className="h2">Loading...</p>
          </div>
        )}

        {showImage && (
          <div className="container">
            {/* Content below the page header */}
            <img src={data.web} alt="Opened Image" style={{ width: "100%" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sponsors;
