import React, { useState } from "react";
import Modal from "../ModalInfo"; // Import the Modal component

const SpeakerItem = ({ slika, ime, prezime, tip, web }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen((prevModalOpen) => !prevModalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const modalContent = (
    <div>
      <p dangerouslySetInnerHTML={{ __html: web }} />
    </div>
  );

  return (
    <div>
      <button className="predavaci" onClick={openModal}>
        <div className="predavaci-container">
          {slika ? (
            <img
              className="predavaci-slikica"
              src={slika}
              alt={`${ime} ${prezime}`}
            />
          ) : null}
          <span className="predavaci-zivotopis">
            <p>
              {ime} {prezime}
              {" (" + tip + ")"}
            </p>
          </span>
        </div>
      </button>
      <Modal
        isOpen={modalOpen}
        closeModal={closeModal}
        content={modalContent}
      />
    </div>
  );
};

export default SpeakerItem;
