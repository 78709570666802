import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const Data = () => {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("/event/oInformacije")) || {}
  );
  const sanitizedHtml = DOMPurify.sanitize(data.general);
  const linkifiedHtml = linkifyHtml(sanitizedHtml);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, "/event/oInformacije");

      const onDataChange = (snapshot) => {
        const items = snapshot.val();
        if (items) {
          setData(items);
          localStorage.setItem("/event/oInformacije", JSON.stringify(items));
        }
      };

      if (navigator.onLine) {
        onValue(dbRef, onDataChange);
        return () => {
          onValue(dbRef, null);
        };
      } else {
        const cachedData = JSON.parse(
          localStorage.getItem("/event/oInformacije")
        );
        if (cachedData) {
          setData(cachedData);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Informations"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div className="container">
        <div className=" centrirano">
          <img className="informacije-img" src={data.logo} />
          <h2 dangerouslySetInnerHTML={{ __html: data.naziv }} />
          <p>{data.datum}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: data.adresa }} />
          <button
            type="button"
            class="btn btn-primary text-start "
            style={{ height: "50px" }}
            dangerouslySetInnerHTML={{
              __html: `<i class="bi bi-geo-alt-fill p-1 bijela-boja" /> Map directions`,
            }}
            onClick={() => window.open(data.gMaps, "_blank")}
          />
        </div>

        <div>
          <p>{data.organizator}</p>

          <p
            dangerouslySetInnerHTML={{
              __html: "Contact: <br>" + data.kontakt,
            }}
          />

          <button
            type="button"
            class="btn btn-primary text-start "
            style={{ height: "50px" }}
            dangerouslySetInnerHTML={{
              __html: `<i class="bi bi-globe p-1 bijela-boja" /> Congress website`,
            }}
            onClick={() => window.open(data.web, "_blank")}
          />
          <p></p>
        </div>
        <div
          style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
          class="text-justify"
        >
          {parse(linkifiedHtml)}
        </div>
      </div>
    </div>
  );
};

export default Data;
