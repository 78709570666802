import React from "react";

const NoticeItem = ({ naslov, vrijeme, poruka }) => {
  return (
    <div className="notice-board-card">
      <h3 class="fs-3">{naslov}</h3>
      <p class="fs-6 p">{vrijeme}</p>
      <div dangerouslySetInnerHTML={{ __html: poruka }} />
    </div>
  );
};

export default NoticeItem;
