const firebaseConfig = {
  apiKey: "AIzaSyCpmHsgh766FWCMJ6Zn0qEzXgL85asPQL4",
  authDomain: "efas-2023-5ec5a.firebaseapp.com",
  databaseURL:
    "https://efas-2023-5ec5a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "efas-2023-5ec5a",
  storageBucket: "efas-2023-5ec5a.appspot.com",
  messagingSenderId: "80288268246",
  appId: "1:80288268246:web:9d38535191b04de4232e77",
  measurementId: "G-279TT1VYD4",
};

export default firebaseConfig;
