import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { initializeApp } from "firebase/app";
import ItemView from "./components/layouts/ItemView";
import { Route, Routes } from "react-router-dom";
import Informations from "./pages/Informations";
import Welcome from "./pages/Welcome";
import firebaseConfig from "./firebaseConfig";
import Televoting from "./pages/Televoting";
import Questions from "./pages/Questions";
import Sponsors from "./pages/Sponsors";
import Survey from "./pages/Survey";
import Virtual from "./pages/Virtual";
import NoticeBoard from "./pages/NoticeBoard";
import Speakers from "./pages/Speakers";
import Notes from "./pages/Notes";
import Schedule from "./pages/Schedule";
import Settings from "./pages/Settings";
import Header from "./components/Header";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const App = () => {
  firebaseApp.database().setPersistenceEnabled(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const dbRef = ref(db, "/administracija/menu");

    const onDataChange = async (snapshot) => {
      const items = snapshot.val();
      if (items) {
        const itemsArray = Object.values(items);
        itemsArray.sort((a, b) => a.redbr - b.redbr);
        if ("caches" in window) {
          const cache = await caches.open("image-cache");
          itemsArray.forEach((item) => {
            fetch(item.icon, { mode: "no-cors" }).then((response) => {
              // Add the image to the cache
              cache.put(item.icon, response);
            });
          });
        }
        setData(itemsArray);
        localStorage.setItem(
          "/administracija/menu",
          JSON.stringify(itemsArray)
        );
      }
    };

    onValue(dbRef, onDataChange, (error) => {
      console.error(error);
      const cachedData = JSON.parse(
        localStorage.getItem("/administracija/menu")
      );
      if (cachedData) {
        setData(cachedData);
      }
    });

    return () => {
      off(dbRef, onDataChange);
    };
  };
  useEffect(() => {
    const cachedData = JSON.parse(localStorage.getItem("/administracija/menu"));
    if (cachedData) {
      setData(cachedData);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="24th EFAS Congress"
        showBackButton={false}
        titleAlignment="center"
        showSettingsButton={true}
      />

      <div className="container ">
        <div className="row">
          {data.map((item) => (
            <ItemView key={item.id} text={item.name} img={item.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Home = () => (
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/Informations" element={<Informations />} />
    <Route path="/Welcome" element={<Welcome />} />
    <Route path="/Schedule/*" element={<Schedule />} />
    <Route path="/Televoting" element={<Televoting />} />
    <Route path="/Questions" element={<Questions />} />
    <Route path="/Sponsors" element={<Sponsors />} />
    <Route path="/Survey" element={<Survey />} />
    <Route path="/Virtual" element={<Virtual />} />
    <Route path="/Notice board" element={<NoticeBoard />} />
    <Route path="/Speakers" element={<Speakers />} />
    <Route path="/Notes" element={<Notes />} />
    <Route path="/Settings" element={<Settings />} />
  </Routes>
);

export default Home;
