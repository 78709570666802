import React from "react";

const Modal = ({ isOpen, closeModal, content }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="">
      <div className="predavaci-zivotopis-p-container">
        <div className="predavaci-zivotopis-p">{content}</div>
      </div>
    </div>
  );
};

export default Modal;
