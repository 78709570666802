import React, { useEffect, useState } from "react";
import { openDB } from "idb";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import SpeakerItem from "../components/layouts/SpeakerItem";
import Header from "../components/Header";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

async function storeData(data) {
  const db = await openDB("speakersDB", 1, {
    upgrade(db) {
      db.createObjectStore("speakers");
    },
  });

  const tx = db.transaction("speakers", "readwrite");
  const store = tx.objectStore("speakers");
  console.log("TESTING SPREMANJE: ", data);
  await store.put(data, "speakers");
  await tx.done;
}

async function getData() {
  const db = await openDB("speakersDB", 1);
  return db.get("speakers", "speakers");
}

const Speakers = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (navigator.onLine) {
        const dbRef = ref(db, "/event/predavaci");
        const onDataChange = (snapshot) => {
          const items = snapshot.val();
          if (items) {
            const itemsArray = Object.values(items);
            const sortByLastName = (a, b) => {
              const lastNameA = a.prezime.toUpperCase();
              const lastNameB = b.prezime.toUpperCase();

              if (lastNameA < lastNameB) {
                return -1;
              }
              if (lastNameA > lastNameB) {
                return 1;
              }
              return 0;
            };
            itemsArray.sort(sortByLastName);
            setData(itemsArray);
            storeData(itemsArray);
          }
        };
        onValue(dbRef, onDataChange);
        return () => {
          onValue(dbRef, null);
        };
      } else {
        const offlineData = await getData();
        if (offlineData) {
          setData(offlineData);
          //setData((prevData) => [...prevData, ...offlineData]);
        }
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Header
        title="Speakers"
        showBackButton={true}
        showSettingsButton={false}
      />

      {data.map((item) => (
        <div key={item.id} className="spacing">
          <SpeakerItem
            slika={item.slika}
            ime={item.ime}
            prezime={item.prezime}
            tip={item.tip}
            web={item.web}
          />
        </div>
      ))}
    </div>
  );
};

export default Speakers;
