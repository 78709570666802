import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, set, push } from "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyCpmHsgh766FWCMJ6Zn0qEzXgL85asPQL4",
  authDomain: "efas-2023-5ec5a.firebaseapp.com",
  databaseURL:
    "https://efas-2023-5ec5a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "efas-2023-5ec5a",
  storageBucket: "efas-2023-5ec5a.appspot.com",
  messagingSenderId: "80288268246",
  appId: "1:80288268246:web:9d38535191b04de4232e77",
  measurementId: "G-279TT1VYD4",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

/* const [tokenFetched, setTokenFetched] = useState(false); */

export const fetchToken = (setTokenFound, setTokenFetched, tokenFetched) => {
  if (tokenFetched) {
    console.log("Token already fetched");
    return;
  }

  return getToken(messaging, {
    vapidKey:
      "BH5AyGf7sjxoTlsHJR-vljNxeRyovdLmmgkE1jgrY8hxZQ5fyD7e1quJU-k04AX5-gJUrwlxCr-yUEZGXNeASPE",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);

        const db = getDatabase();
        set(ref(db, "administracija/tokeni/" + currentToken), currentToken);
        setTokenFetched(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export function registerWorker() {
  const swPath = "/service-worker.js";

  if ("serviceWorker" in navigator && process.env.NODE_ENV !== "production") {
    window.addEventListener("load", function () {
      navigator.serviceWorker.register(swPath).then((registration) => {
        console.log("Service worker registered", registration.scope);
      });
    });
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
