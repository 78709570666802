const PosaljiNotif = () => {
  return (
    <div className="container">
      <input type="text" id="inputField1" enterKeyHint="ID"></input>
      <input type="text" id="inputField2"></input>
      <textarea type="text" id="inputField2"></textarea>
    </div>
  );
};

export default PosaljiNotif;
