import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import Header from "../components/Header";
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const Virtual = () => {
  const [showWebContent, setShowWebContent] = useState(false);
  const [webUrl, setWebUrl] = useState("");
  const [showLoading, setShowLoading] = useState(true);

  const openUrl = (url) => {
    setWebUrl(url);
    setShowWebContent(true);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, "/event/virtual");
      const onDataChange = (snapshot) => {
        const items = snapshot.val();
        if (items && items.virtual) {
          setData(items);
          openUrl(items.virtual, "_blank");
        }
      };
      onValue(dbRef, onDataChange);
      return () => {
        onValue(dbRef, null);
      };
    };

    setTimeout(() => {
      setShowLoading(false);
      setShowWebContent(true);
    }, 1000);
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Virtual"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div className="container">
        {showLoading && (
          <div className="">
            {/* Loading indicator */}
            <p className="h2">Loading...</p>
          </div>
        )}
        {showWebContent && (
          <div className="container">
            {/* Content below the page header */}
            <iframe
              title="Web Content"
              src={webUrl}
              width="100%"
              height="1000px"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Virtual;
