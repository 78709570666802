import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import NoticeItem from "../components/layouts/NoticeItem";
import Header from "../components/Header";
import { openDB } from "idb";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

async function storeData(data) {
  const db = await openDB("noticeDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("notice")) {
        db.createObjectStore("notice");
      }
    },
  });
  const tx = db.transaction("notice", "readwrite");
  const store = tx.objectStore("notice");
  console.log("TESTING SPREMANJE notice: ", data);
  await store.put(data, "notice");
  await tx.done;
}

async function getData() {
  const db = await openDB("noticeDB", 1);
  return db.get("notice", "notice");
}

const NoticeBoard = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (navigator.onLine) {
        const dbRef = ref(db, "/administracija/notifikacije");
        const onDataChange = async (snapshot) => {
          const items = snapshot.val();
          if (items) {
            const itemsArray = Object.values(items);

            const strWbr = itemsArray.map((item) => {
              if (typeof item === "object" && item.poruka) {
                item.poruka = item.poruka.replaceAll(";", "<br>");
              }
              return item;
            });
            setData(strWbr);
            storeData(strWbr);
          }
        };
        onValue(dbRef, onDataChange);
        return () => {
          onValue(dbRef, null);
        };
      } else {
        const offlineData = await getData();
        console.log("offline data: ", offlineData);
        if (offlineData) {
          setData((prevData) => [...prevData, ...offlineData]);
        }
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Header
        title="Notice board"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div className="container">
        {data.map((item) => (
          <div key={item.id}>
            <NoticeItem
              naslov={item.naslov}
              vrijeme={item.vrijeme}
              poruka={item.poruka}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoticeBoard;
