import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default class ItemView extends Component {
  render() {
    return (
      <div class="col-md-4 col-4 mb-3 text-decoration-none">
        <Link to={`${this.props.text}`}>
          <Button className="card text-center text-decoration-none">
            {/* <img className="img" src={this.props.img} alt="nema" /> */}
            <img
              className="img"
              src={this.props.img}
              alt="nema"
              onError={(e) => {
                if ("caches" in window) {
                  caches.match(this.props.img).then((response) => {
                    if (response) {
                      e.target.src = URL.createObjectURL(response);
                    }
                  });
                }
              }}
            />
            <p class="text-truncate text-md text-decoration-none">
              {this.props.text}
            </p>
          </Button>
        </Link>
      </div>
    );
  }
}

/* import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class ItemView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
    };
  }

  componentDidMount() {
    this.loadImage(this.props.img);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.img !== this.props.img) {
      this.loadImage(this.props.img);
    }
  }

  loadImage(img) {
    const cachedImg = localStorage.getItem(img);
    if (cachedImg) {
      this.setState({ imgSrc: cachedImg });
    } else {
      fetch(img)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result;
            localStorage.setItem(img, base64data);
            this.setState({ imgSrc: base64data });
          };
          reader.readAsDataURL(blob);
        });
    }
  }

  render() {
    return (
      <div class="col-md-4 col-4 mb-3 text-decoration-none">
        <Link to={`${this.props.text}`}>
          <Button className="card text-center text-decoration-none">
            <img className="img" src={this.state.imgSrc} alt="nema" />
            <p class="text-truncate text-md text-decoration-none">
              {this.props.text}
            </p>
          </Button>
        </Link>
      </div>
    );
  }
}

export default ItemView; */
