import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import React, { useEffect, useState } from "react";
import NotesItem from "../components/layouts/NotesItem";
import Cookies from "js-cookie";
import Header from "../components/Header";
import { openDB } from "idb";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

async function storeData(data) {
  const db = await openDB("notesDB", 1, {
    upgrade(db) {
      db.createObjectStore("notes");
    },
  });

  const tx = db.transaction("notes", "readwrite");
  const store = tx.objectStore("notes");
  await store.put(data, "notes");
  await tx.done;
}

async function getData() {
  const db = await openDB("notesDB", 1);
  return db.get("notes", "notes");
}

const Notes = () => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [email, setEmail] = useState("");

  const saveToCookie = async () => {
    const lowercaseEmail = inputValue.toLowerCase();
    Cookies.set("userData", updateEmailTocka(lowercaseEmail), { expires: 365 });
    await storeData({ email: lowercaseEmail });
    alert("Email saved!");
    window.location.reload();
  };
  const clearStoredEmail = async () => {
    Cookies.remove("userData");
    await storeData({ email: "" });
    setEmail("");
    alert("Email cleared!");
    window.location.reload();
  };
  const updateEmailTocka = (email) => {
    const labelEmail = email.replace(/\./g, "+");
    return labelEmail;
  };
  const updateEmailPlus = (email) => {
    const praviMejl = email.replace(/\+/g, ".");
    return praviMejl;
  };
  const sendEmail = () => {
    const subject = "EFAS 2023 notes";
    const body = data
      .map(
        (item, index) =>
          `${index + 1}. ${item.tema}\n${item.time}\n${item.text}`
      )
      .join("\n\n");
    window.location.href = `mailto:${updateEmailPlus(
      email
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const storedEmail = Cookies.get("userData");

      if (storedEmail) {
        setEmail(storedEmail);

        if (navigator.onLine) {
          const dbRef = ref(
            db,
            `/administracija/korisnici/${storedEmail}/biljeske`
          );

          const onDataChange = (snapshot) => {
            const items = snapshot.val();
            if (items) {
              const itemsArray = Object.values(items);
              setData(itemsArray);
              storeData(itemsArray);
            }
          };

          onValue(dbRef, onDataChange);
          return () => {
            onValue(dbRef, null);
          };
        } else {
          const offlineData = await getData();
          console.log("offline data: ", offlineData);
          if (offlineData) {
            setData((prevData) => [...prevData, ...offlineData]);
          }
        }
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Header
        title="Notes"
        showBackButton={true}
        showSettingsButton={false}
        showEmailButton={true}
        sendEmail={sendEmail}
      />
      <div className="container">
        {email ? (
          <div className="predavaci-zivotopis-p">
            <p className="notes-header">
              To write a note, select the desired lecture in the schedule and
              press the pencil icon.
            </p>

            <div className="notes-header poravnanje">
              <p>Email: {updateEmailPlus(email)} </p>

              <button className="save-clear-button" onClick={clearStoredEmail}>
                Clear Email
              </button>
            </div>
            {data &&
              data.map((item) => (
                <div key={item.id}>
                  <NotesItem
                    id={item.id}
                    tema={item.tema}
                    time={item.time}
                    text={item.text}
                  />
                </div>
              ))}
          </div>
        ) : (
          <div className="predavaci-zivotopis-p">
            <p className="notes-header poravnanje">
              In order to use the notes, you need to enter your email address.
              <br />
            </p>
            <div className="notes-header poravnanje">
              <input
                className="email-input"
                type="tekst"
                id="inputField"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter email"
              />
              <button className="save-clear-button" onClick={saveToCookie}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Notes;
