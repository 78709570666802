import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import React, { useEffect, useState } from "react";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const About = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, "/administracija/about");
      const onDataChange = (snapshot) => {
        const items = snapshot.val();
        if (items) {
          setData(items);
        }
      };
      onValue(dbRef, onDataChange);
      return () => {
        onValue(dbRef, null);
      };
    };
    fetchData();
  }, []);
  return (
    <div className="container">
      <div className="container centrirano">
        <img className="about-img" src={data.logo} alt="" />
        <p>Mob: {data.tel}</p>
        <p>
          Email:
          <a href={`mailto:${data.email}`}> {data.email}</a>
        </p>
        <p>Address: {data.adresa}</p>
        <a href={data.web}>© BelSoft</a>
      </div>
    </div>
  );
};
export default About;
