import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = ({
  title,
  showBackButton,
  showSettingsButton,
  titleAlignment,
  showEmailButton,
  sendEmail,
}) => {
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate("Settings");
  };

  const handleBackClick = () => {
    navigate(-1); // navigate back
  };

  return (
    <AppBar position="sticky" color="primary" style={{ marginBottom: "10px" }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Back Button */}
          {showBackButton && (
            <IconButton edge="start" color="inherit" onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
          )}

          {/* Title */}
          <Typography variant="h5">
            <b>{title}</b>
          </Typography>
        </div>
        <div>
          {showSettingsButton && (
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleSettingsClick}
            >
              <SettingsIcon />
            </IconButton>
          )}
          {/* Email Button */}
          {showEmailButton && (
            <button onClick={sendEmail} class="btn btn-link">
              <i class="bi bi-envelope-plus-fill bijela-boja"></i>
            </button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
