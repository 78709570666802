import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import ProgramRow from "../components/layouts/ProgramRow";
import Header from "../components/Header";
import { openDB } from "idb";
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

async function storeData(data) {
  const db = await openDB("treciDB", 1, {
    upgrade(db) {
      db.createObjectStore("program");
    },
  });

  const tx = db.transaction("program", "readwrite");
  const store = tx.objectStore("program");
  await store.put(data, "program");
  await tx.done;
}

async function getData() {
  const db = await openDB("treciDB", 1);
  return db.get("program", "program");
}

const TreciDan = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (navigator.onLine) {
          const dbRef = ref(db, "/event/program");
          const onDataChange = (snapshot) => {
            const items = snapshot.val();
            if (items) {
              const itemsArray = Object.values(items);
              const filterData = itemsArray.filter((item) => {
                return item.datum === "07.10.2023";
              });
              console.log("Online data:", filterData);
              setData(filterData);
              storeData(filterData);
            }
          };

          onValue(dbRef, onDataChange);
          return () => {
            onValue(dbRef, null);
          };
        } else {
          const offlineData = await getData();
          if (offlineData) {
            console.log("Offline data:", offlineData);
            setData(offlineData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  /* if (loading) {
    return (
      <div>
        <Header
          title={"Loading..."}
          showBackButton={true}
          showSettingsButton={false}
        />
        <div>loading...</div>
      </div>
    );
  } */
  return (
    <div>
      <Header
        title="Saturday, October 7th 2023"
        showBackButton={true}
        showSettingsButton={false}
      />
      <div>
        {data.map((item) => (
          <div key={item.id}>
            <ProgramRow
              vrijeme={item.vrijeme}
              tema={item.tema}
              opis={item.opis}
              predavaci={item.predavaci}
              razina={item.razina}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TreciDan;
