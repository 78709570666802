import React, { useEffect, useState } from "react";
import BackButton from "../components/back_button";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import Header from "../components/Header";
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const Questions = () => {
  const [showWebContent, setShowWebContent] = useState(false);
  const [webUrl, setWebUrl] = useState("");

  const openUrl = (url) => {
    setWebUrl(url);
    setShowWebContent(true);
    window.open(url);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, "/event/pitanja");
      const onDataChange = (snapshot) => {
        const items = snapshot.val();
        if (items) {
          setData(items);
          openUrl(items.pitanja);
        }
      };
      onValue(dbRef, onDataChange);
      return () => {
        onValue(dbRef, null);
      };
    };
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Questions"
        showBackButton={true}
        showSettingsButton={false}
      />
      {showWebContent && (
        <div className="container">
          {/* Content below the page header */}
          <iframe
            title="Web Content"
            src={webUrl}
            width="100%"
            height="1000px"
          />
        </div>
      )}
    </div>
  );
};

export default Questions;
