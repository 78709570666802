import React from "react";
import BackButton from "../components/back_button";
import { Link, Route, Routes } from "react-router-dom";
import NultiDan from "./NultiDan";
import DrugiDan from "./DrugiDan";
import TreciDan from "./TreciDan";
import Header from "../components/Header";
const Schedule = () => {
  return (
    <div>
      <Header
        title="Schedule"
        showBackButton={true}
        showSettingsButton={false}
      />

      <div class="d-flex flex-column gap-2">
        <Link to="./NultiDan" className="container">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
          >
            Thursday, October 5th 2023
          </button>
        </Link>
        <Link to="./DrugiDan" className="container">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
          >
            Friday, October 6th 2023{" "}
          </button>
        </Link>
        <Link to="./TreciDan" className="container">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-start"
          >
            Saturday, October 7th 2023
          </button>
        </Link>
      </div>
    </div>
  );
};
const Prikaz = () => (
  <Routes>
    <Route path="/" element={<Schedule />} />
    <Route path="NultiDan" element={<NultiDan />} />
    <Route path="DrugiDan" element={<DrugiDan />} />
    <Route path="TreciDan" element={<TreciDan />} />
  </Routes>
);

export default Prikaz;
